import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import TopHeader from "../../../components/top-header"

import { Row, Container, Col } from 'react-bootstrap/'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import RetmesIot from "../../../images/retmes-nesnelerin-interneti.svg"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

const Iot = ({intl}) => {
  const title = intl.formatMessage({ id: "modules_content1__1" })
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(relativePath: { eq: "modules/veri.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (<Layout>
    <TopHeader />
    <SEO title={title} />
    <Container>
 
      <div className='image-wrapper'>
        <Img style={{ borderRadius: '1rem' }} alt="retmes veri ve kontrol" fluid={data.headerImage.childImageSharp.fluid} />
        <h2 className='image-wrapper-text'><FormattedMessage id="modules_title1__1" /></h2>
      </div>
      <h1 className='page-title'>{title}</h1>
      <Row className='content-page-row'>
        <Col>
          <p className='content-page-p' sm={6}>
            <FormattedMessage id="modules_iot_desc" />
          </p>
        </Col>
        <Col style={{ textAlign: '-webkit-center' }} sm={6}>
          <img src={RetmesIot} alt="retmes nesnelerin interneti" />
        </Col>
      </Row>
      <Row style={{ height: '50vh' }}>
      </Row>
    </Container>
  </Layout>

  );
}



export default injectIntl(Iot)